<template>
  <div class="std-intro-container center">
    <div class="std-intro column">

      <span clsas="std-intro-title">
        <p class="std-intro-title-text">일반고특화(고교위탁) 직업훈련</p>
      </span>

      <span clsas="std-intro-content">
        <p class="std-intro-content-text">1) 고용노동부와 교육부에서 취업을 희망하는 일반고 3학년 학생을 대상으로 지정된 훈련기관에서 1년동안 직업교육을 받고, 우수 산업체에 취업 (대학 진학도 가능) 할 수 있도록 도입한 직업교육 위탁 과정입니다.</p>
        <p class="std-intro-content-text">2) 교육비, 교재비, 실습재료비 전액 국비 100% 지원이며, 매월 출석률에 따라 교육장려금(월 최대 116000원)이 지원되는 제도입니다.</p>
        <p class="std-intro-content-text">3) 체계적인 자격시험 대비 교육으로, 국가공인 자격증을 취득 할 수 있습니다.</p>
        <p class="std-intro-content-text">4) 창의적 체험 활동 지원 : 직업기초소양, 노동인권교육, 대내외경연대회, 심리상담, 인문체험, 외식체험, 봉사활동, 독서프로그램, 취업교육(자기소개서 작성, 면접체험, 동아리활동</p>
        <p class="std-intro-content-text">5) 본교는 입학장학금, 교내경연/성적우수 장학금, 출결, 봉사, 공로 등 다양한 장학제도를 운영합니다.</p>
        <p class="std-intro-content-text">6) 산업체 전문가 초청 취업 특강을 비롯, 지속적인 산학협력을 통해 다양한 교육기회와 실무경험의 장을 마련하여, 확실한 취업연계가 가능합니다.</p>
      </span>

      <span class="std-intro-sub-title">
        <p class="std-intro-sub-title-text">일반고특화(고교위탁) 수강 대상자</p>
      </span>

      <hr>
      <table class="std-intro-table">
        <tr class="std-intro-table-header">
          <th
          v-for="(item, index) in stdTable.header"
          :key="'table-header-'+index">
          {{item}}
          </th>
        </tr>

        <tr class="std-intro-table-content"
        v-for="(subItem, subIndex) in stdTable.content"
        :key="'table-sub-header-'+subIndex">
          <td>{{subItem.subHeader}}</td>
          <td>
            <p
            v-for="(subContent, subContentIndex) in subItem.subContent"
            :key="'table-sub-content-'+subContentIndex">{{subContent}}</p>
          </td>
        </tr>

      </table>

    </div>
  </div>
</template>

<script>
import { stdTable } from '../../data/std';

export default {
  data() {
    return {
      stdTable,
    }
  }
}
</script>

<style>

</style>