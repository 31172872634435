export const stdTable = {

    header : ['구분', '일반고특화(고교위탁) 직업훈련'],

    content : [
        { 
            subHeader : '수강대상',
            subContent : [
                '일반 인문계 고등학교 3학년 학생((2학년 2학기부터 접수 가능)',
            ]
        },

        { 
            subHeader : '수강절차',
            subContent : [
                '5년(실업자, 재직자, 자영업 여부에 관계없이 국민내일배움카드 한 장으로 계좌 발급일부터 5년간 사용가능)'
            ]
        },

        { 
            subHeader : '구비서류) 본인준비',
            subContent : [
                '1) 입학지원서 1부(다운로드 링크 클릭 및 본교 홈페이지 공지사항 참고)',
                '2) 주민등록증 또는 청소년증',
                '3) 부모님 동의서 1부(다운로드 링크 클릭 및 본교 홈페이지 공지사항 참고)',
                '4) 농협 또는 신한은행 본인 명의 계좌 개설 및 해당은행 공인인증서 발급',
                '5) 반명함 사진 1매',
            ]
        },

        { 
            subHeader : '구비서류) 학교발급',
            subContent : [
                '1) 학교장 추천서 1부(다운로드 링크 클릭 및 본교 홈페이지 공지사항 참고)',
                '2) 재학증명서 1부',
                '3) 생활기록부 1부'
            ]
        },

    ]

}

